<template>
  <div v-if="reservationReview" class="review-trip">
    <TheAlerts />
    <div class="guest-header">
      <div class="guest-header__inner container">
        <div v-if="!getLogoUrl()" class="guest-header__name">
          {{ company.name }}
        </div>
        <div v-if="getLogoUrl()" class="guest-header__view">
          <div
            class="guest-header__logo"
            :style="`background-image:url('${getLogoUrl()}')`"
          ></div>
        </div>
      </div>
    </div>
    <div class="review-trip__body container">
      <v-form
        v-if="!hasReviewed"
        ref="form"
        class="review-trip__form"
        :lazy-validation="true"
      >
        <StarRating
          v-model="reservationReview.rating"
          class="review-trip__star-review"
          dense
          :show-rating="false"
          :max-rating="5"
          :star-size="25"
        />

        <h2 class="review-trip__heading">
          Thanks so much for leaving us a rating,
          {{ reservationReview.customer.firstName }}!
        </h2>

        <div v-if="isNegative" class="review-trip__negative">
          <p class="review-trip__copy">
            We’re sorry to hear that your experience was less than satisfactory.
          </p>

          <h3 class="review-trip__subheading">
            Please let us know what went wrong, and what we can do to improve
            your next trip with {{ company.name }}:
          </h3>
        </div>

        <div v-if="!isNegative" class="review-trip__positive">
          <p class="review-trip__copy">
            We’d love to hear more about your trip. If you’d like, please leave
            a comment below describing what went well and what we can do better
            next time to improve your experience. We strive to give every
            passenger the highest-quality service we can possibly provide, and
            your feedback helps us do just that.
          </p>

          <h3 class="review-trip__subheading">
            Thanks for traveling with {{ company.name }}!
          </h3>
        </div>

        <div class="form-group review-trip__review-group">
          <v-textarea
            id="review-trip-text-area-enter-review"
            v-model="reservationReview.review"
            class="form-control review-trip__review"
            name="review"
            flat
            solo
            autofocus
            placeholder="Enter your review"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Please enter your review',
                error: 'Please enter your review',
              }),
            ]"
          />
        </div>
        <div class="review-trip__form__actions">
          <v-btn
            id="review-trip-button-submit-review"
            color="primary"
            :loading="isProcessing"
            @click="update"
          >
            Submit
          </v-btn>
        </div>
      </v-form>

      <div v-if="hasReviewed" class="review-trip__response">
        <h2 class="review-trip__heading">Thank you for your feedback!</h2>

        <div v-if="!isNegative" class="review-trip__positive">
          <p class="review-trip__copy">
            We’re happy to hear that you enjoyed your trip with
            {{ company.name }}. We look forward to working with you again in the
            future!
          </p>
        </div>
      </div>

      <div style="font-size: 20px; margin-bottom: 20px; margin-top: 20px">
        <span>
          Powered by &nbsp;&nbsp;
          <img
            data-v-d4e28758=""
            src="@/assets/images/logo-coachrail.svg"
            style="position: relative; width: 120px; top: 2px; left: 2px"
          />
        </span>
      </div>
    </div>
  </div>
  <!-- <v-loader v-else /> -->
</template>
<script>
import StarRating from 'vue-star-rating'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { environmentPrefix } from '@/utils/env'
import TheAlerts from '@/components/TheAlerts.vue'
import reviews from '@/services/reviews'

export default {
  components: {
    StarRating,
    TheAlerts,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      review: {
        rating: 4,
        id: null,
        hash: null,
        company: {},
        referredCompany: null,
        customer: {
          first_name: '',
        },
        review: null,
      },
      isProcessing: false,
      hasReviewed: false,
      reservationReview: null,
    }
  },
  computed: {
    company() {
      return this.reservationReview?.company
    },
    isNegative() {
      if (this.reservationReview?.rating) {
        return this.reservationReview.rating < 4
      }
      return null
    },
  },
  async mounted() {
    const reviewResponse = await reviews.getReviewByHash(this.hash)

    const reservationReviewDetails =
      reviewResponse.data.reservationReviewDetails

    if (reservationReviewDetails.isMarketplace) {
      const marketplaceReviewUrl = `https://${environmentPrefix()}.charterup.com/tripreview/${
        this.hash
      }`
      window.open(marketplaceReviewUrl, '_self')
    }

    this.hasReviewed = reservationReviewDetails.reviewAlreadySubmitted

    this.reservationReview = reservationReviewDetails.reservationReview

    if (!this.hasReviewed) {
      this.reservationReview.rating = this.$route.query.stars || 5
    }
  },
  methods: {
    isRequired,
    isNotEmpty,
    getLogoUrl() {
      if (this.reservationReview.company && this.company.lightLogoUrl) {
        return this.company.lightLogoUrl
      }
      return null
    },
    async update() {
      const isValid = this.$refs.form.validate()
      this.isProcessing = true

      if (!isValid) {
        this.isProcessing = false
        return
      }

      await reviews
        .createReviewByHash({
          rating: this.reservationReview.rating,
          hash: this.hash,
          review: this.reservationReview.review,
        })
        .then(() => {
          this.isProcessing = false
          this.hasReviewed = true
          this.$forceUpdate()
        })
        .catch((errors) => {
          this.$store.dispatch('app/showAlert', {
            message:
              'Unable to submit review, please try again later! Your feedback is important to us.',
            type: 'error',
          })

          this.isProcessing = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.review-trip {
  background: $white;
  min-height: 100vh;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__body {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__form,
  &__response {
    width: 100%;
    max-width: 700px;
    margin: 15 * 3 15;
  }

  &__star-review {
    margin-bottom: 30 * 1.5;
    justify-content: center;
  }

  &__heading {
    margin-bottom: 30;
  }

  &__subheading {
    margin-top: 30;
    line-height: 1.45em;
  }

  &__review-group {
    margin: 15 * 3 0;
  }

  &__review {
    // min-height: 200px;
  }

  &__shit-talking {
    &-list {
      margin-top: 30 * 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-item {
      width: 100px;

      &__yp {
        width: 50px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      & + & {
        margin-left: 30;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &__form {
      margin: 80px 15 30;
    }

    &__review {
      width: 80%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 992px) {
    &__form {
      margin-top: 120px;
    }
  }

  .guest-header {
    background-color: $blue-dark;
    background-image: linear-gradient(to right, $blue-darker, $blue-dark);
    min-height: 100px;
    color: $white;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 15 30;
      background-image: url('~@/assets/images/map.svg');
      background-position: center -125px;
      background-repeat: no-repeat;
      background-size: cover;
      flex: 1 1 auto;
      width: 100%;
    }

    &__name {
      font-size: 30px;
      padding: 15 0;
    }

    &__view {
      width: 180px;
    }

    &__logo {
      width: 100%;
      padding-top: 35%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
</style>
