import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { ApiResult, ReservationReview, ReservationReviewAverageResult } from '@/models/dto'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  getReviewByHash(hash: string): Promise<AxiosResponse<ApiResult>> {
    return httpService.get(`https://${baseUrl()}/reservations/v2/reviews/${hash}`)
  },
  createReviewByHash(model: { rating: number, hash: string, review: string}): Promise<AxiosResponse<ApiResult>> {
    return httpService.post(`https://${baseUrl()}/reservations/v2/reviews`, model)
  },
  enableReview(reviewId: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.get(`https://${baseUrl()}/reviews/${reviewId}/enable`)
  },
  disableReview(reviewId: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.get(`https://${baseUrl()}/reviews/${reviewId}/disable`)
  },
  getReviews(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<ReservationReview>>> {
    const { pageSize = 10, page = 1, filters, sorts } = params

    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/reservationReviews?${query}`
    return httpService.get(url)
  },
  getAverages(params: { fromDate: string, toDate: string, marketId: number, partnerTypeId: number, referralCompanyId: number }): Promise<AxiosResponse<ReservationReviewAverageResult>> {
    const {
      fromDate,
      toDate,
      marketId,
      partnerTypeId,
      referralCompanyId,
    } = params

    let query = `fromDate=${fromDate}&toDate=${toDate}`

    if (marketId) {
      query += `&marketId=${marketId}`
    }
    if (partnerTypeId) {
      query += `&partnerTypeId=${partnerTypeId}`
    }
    if (referralCompanyId) {
      query += `&referralCompanyId=${referralCompanyId}`
    }

    query = encodeURI(query)

    const url = `https://${baseUrl()}/reviews/averages?${query}`
    return httpService.get(url)
  },
}
